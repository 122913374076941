@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.4;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #4b4b4b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #272727;
}

h1 {
  font-size: 30px;
  margin-top: 0;
}

@media (min-width: 768px) {
  h1 {
    font-size: 40px;
  }
}

h3 {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: unset;
  font-size: 14px;
  transition: 0.3s;
}

button {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background: unset;
  transition: 0.3s;
}

button:focus {
  outline: none;
}

p {
  font-size: 14px;
}

li,
label {
  font-size: 14px;
}

@media (min-width: 768px) {
  p,
  a,
  li,
  label {
    font-size: 16px;
  }
}

a:hover:not([disabled]),
button:hover:not([disabled]) {
  opacity: 0.7;
}
input::-webkit-credentials-auto-fill-button {
  margin: 0;
  width: 0;
  background-color: transparent;
}

@media (max-width: 1023px) {
  #fb-root {
    display: none;
  }
}
